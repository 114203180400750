/*visually_impaired*/
/*кнопки панели toolbar-visually-impaired*/
.toolbar-visually-impaired__buttons [data-value="black"] {
  background: #fff !important;
  color: #000 !important;
}
.toolbar-visually-impaired__buttons [data-value="white"] {
  background: #000 !important;
  color: #fff !important;
}
.toolbar-visually-impaired__buttons [data-value="blue"] {
  background: #abd4f6 !important;
  color: #002663 !important;
}
.toolbar-visually-impaired__buttons [data-value="braun"] {
  background: #eee8c5 !important;
  color: #493934 !important;
}
.toolbar-visually-impaired__buttons [data-value="black-green"] {
  background: #000 !important;
  color: #c1e770 !important;
}
/*страница для слабовидящих по умолчанию*/
.visuallyimpaired {
  background-image: none !important;
  background-color: transparent !important;
}
.visuallyimpaired * {
  background-image: none !important;
  background-color: transparent !important;
}
.visuallyimpaired body,
.visuallyimpaired div,
.visuallyimpaired h1,
.visuallyimpaired h2,
.visuallyimpaired h3,
.visuallyimpaired h4,
.visuallyimpaired h5,
.visuallyimpaired h6,
.visuallyimpaired ul,
.visuallyimpaired li,
.visuallyimpaired table,
.visuallyimpaired tr,
.visuallyimpaired td,
.visuallyimpaired form,
.visuallyimpaired input,
.visuallyimpaired textarea,
.visuallyimpaired p,
.visuallyimpaired a,
.visuallyimpaired select,
.visuallyimpaired * {
  color: #000 !important;
  font-family: Verdana !important;
  font-weight: normal !important;
  letter-spacing: 0.01em !important;
  line-height: 1.5 !important;
}
.visuallyimpaired * {
  background-color: #fff !important;
  color: #000 !important;
  fill: #000 !important;
  border-color: #000 !important;
}
.visuallyimpaired input {
  color: #000 !important;
  font-size: 100% !important;
}
.visuallyimpaired input:active {
  outline: 2px solid #000 !important;
}
.visuallyimpaired input:focus {
  outline: 2px solid #000 !important;
}
.visuallyimpaired a:focus {
  outline: 2px dashed #000 !important;
}
.visuallyimpaired textarea {
  color: #000 !important;
  font-size: 100% !important;
}
.visuallyimpaired textarea:active {
  outline: 2px solid #000 !important;
}
.visuallyimpaired textarea:focus {
  outline: 2px solid #000 !important;
}
.visuallyimpaired button:focus {
  outline: 3px solid #000 !important;
}
.visuallyimpaired ol li::before {
  color: #000 !important;
}
.visuallyimpaired .active {
  border-color: #000 !important;
  border-width: 1px;
  border-style: solid;
}
.visuallyimpaired img {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
}
.visuallyimpaired [data-value="black"] {
  background: #fff !important;
  color: #000 !important;
}
.visuallyimpaired [data-value="white"] {
  background: #000 !important;
  color: #fff !important;
}
.visuallyimpaired [data-value="blue"] {
  background: #abd4f6 !important;
  color: #002663 !important;
}
.visuallyimpaired [data-value="braun"] {
  background: #eee8c5 !important;
  color: #493934 !important;
}
.visuallyimpaired [data-value="black-green"] {
  background: #000 !important;
  color: #c1e770 !important;
}
.visuallyimpaired .visuallyimpaired .header-top-toolbar-fontsize:before {
  border-top: 2px solid #000 !important;
}
/*visuallyimpaired*/
/*цвета*/
body.color-black * {
  background-color: #fff !important;
  color: #000 !important;
  fill: #000 !important;
  border-color: #000 !important;
}
body.color-black input {
  color: #000 !important;
  font-size: 100% !important;
}
body.color-black input:active {
  outline: 2px solid #000 !important;
}
body.color-black input:focus {
  outline: 2px solid #000 !important;
}
body.color-black a:focus {
  outline: 2px dashed #000 !important;
}
body.color-black textarea {
  color: #000 !important;
  font-size: 100% !important;
}
body.color-black textarea:active {
  outline: 2px solid #000 !important;
}
body.color-black textarea:focus {
  outline: 2px solid #000 !important;
}
body.color-black button:focus {
  outline: 3px solid #000 !important;
}
body.color-black ol li::before {
  color: #000 !important;
}
body.color-black .active {
  border-color: #000 !important;
  border-width: 1px;
  border-style: solid;
}
body.color-black img {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
}
body.color-black [data-value="black"] {
  background: #fff !important;
  color: #000 !important;
}
body.color-black [data-value="white"] {
  background: #000 !important;
  color: #fff !important;
}
body.color-black [data-value="blue"] {
  background: #abd4f6 !important;
  color: #002663 !important;
}
body.color-black [data-value="braun"] {
  background: #eee8c5 !important;
  color: #493934 !important;
}
body.color-black [data-value="black-green"] {
  background: #000 !important;
  color: #c1e770 !important;
}
body.color-black .header-top-toolbar-fontsize::before {
  border-top: 2px solid #000 !important;
}
body.color-white * {
  background-color: #000 !important;
  color: #fff !important;
  fill: #fff !important;
  border-color: #fff !important;
}
body.color-white input {
  color: #fff !important;
  font-size: 100% !important;
}
body.color-white input:active {
  outline: 2px solid #fff !important;
}
body.color-white input:focus {
  outline: 2px solid #fff !important;
}
body.color-white a:focus {
  outline: 2px dashed #fff !important;
}
body.color-white textarea {
  color: #fff !important;
  font-size: 100% !important;
}
body.color-white textarea:active {
  outline: 2px solid #fff !important;
}
body.color-white textarea:focus {
  outline: 2px solid #fff !important;
}
body.color-white button:focus {
  outline: 3px solid #fff !important;
}
body.color-white ol li::before {
  color: #fff !important;
}
body.color-white .active {
  border-color: #fff !important;
  border-width: 1px;
  border-style: solid;
}
body.color-white img {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
}
body.color-white [data-value="black"] {
  background: #fff !important;
  color: #000 !important;
}
body.color-white [data-value="white"] {
  background: #000 !important;
  color: #fff !important;
}
body.color-white [data-value="blue"] {
  background: #abd4f6 !important;
  color: #002663 !important;
}
body.color-white [data-value="braun"] {
  background: #eee8c5 !important;
  color: #493934 !important;
}
body.color-white [data-value="black-green"] {
  background: #000 !important;
  color: #c1e770 !important;
}
body.color-white .header-top-toolbar-fontsize::before {
  border-top: 2px solid #fff !important;
}
body.color-blue * {
  background-color: #abd4f6 !important;
  color: #002663 !important;
  fill: #002663 !important;
  border-color: #002663 !important;
}
body.color-blue input {
  color: #002663 !important;
  font-size: 100% !important;
}
body.color-blue input:active {
  outline: 2px solid #002663 !important;
}
body.color-blue input:focus {
  outline: 2px solid #002663 !important;
}
body.color-blue a:focus {
  outline: 2px dashed #002663 !important;
}
body.color-blue textarea {
  color: #002663 !important;
  font-size: 100% !important;
}
body.color-blue textarea:active {
  outline: 2px solid #002663 !important;
}
body.color-blue textarea:focus {
  outline: 2px solid #002663 !important;
}
body.color-blue button:focus {
  outline: 3px solid #002663 !important;
}
body.color-blue ol li::before {
  color: #002663 !important;
}
body.color-blue .active {
  border-color: #002663 !important;
  border-width: 1px;
  border-style: solid;
}
body.color-blue img {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
}
body.color-blue [data-value="black"] {
  background: #fff !important;
  color: #000 !important;
}
body.color-blue [data-value="white"] {
  background: #000 !important;
  color: #fff !important;
}
body.color-blue [data-value="blue"] {
  background: #abd4f6 !important;
  color: #002663 !important;
}
body.color-blue [data-value="braun"] {
  background: #eee8c5 !important;
  color: #493934 !important;
}
body.color-blue [data-value="black-green"] {
  background: #000 !important;
  color: #c1e770 !important;
}
body.color-blue .header-top-toolbar-fontsize::before {
  border-top: 2px solid #002663 !important;
}
body.color-braun * {
  background-color: #eee8c5 !important;
  color: #493934 !important;
  fill: #493934 !important;
  border-color: #493934 !important;
}
body.color-braun input {
  color: #493934 !important;
  font-size: 100% !important;
}
body.color-braun input:active {
  outline: 2px solid #493934 !important;
}
body.color-braun input:focus {
  outline: 2px solid #493934 !important;
}
body.color-braun a:focus {
  outline: 2px dashed #493934 !important;
}
body.color-braun textarea {
  color: #493934 !important;
  font-size: 100% !important;
}
body.color-braun textarea:active {
  outline: 2px solid #493934 !important;
}
body.color-braun textarea:focus {
  outline: 2px solid #493934 !important;
}
body.color-braun button:focus {
  outline: 3px solid #493934 !important;
}
body.color-braun ol li::before {
  color: #493934 !important;
}
body.color-braun .active {
  border-color: #493934 !important;
  border-width: 1px;
  border-style: solid;
}
body.color-braun img {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
}
body.color-braun [data-value="black"] {
  background: #fff !important;
  color: #000 !important;
}
body.color-braun [data-value="white"] {
  background: #000 !important;
  color: #fff !important;
}
body.color-braun [data-value="blue"] {
  background: #abd4f6 !important;
  color: #002663 !important;
}
body.color-braun [data-value="braun"] {
  background: #eee8c5 !important;
  color: #493934 !important;
}
body.color-braun [data-value="black-green"] {
  background: #000 !important;
  color: #c1e770 !important;
}
body.color-braun .header-top-toolbar-fontsize::before {
  border-top: 2px solid #493934 !important;
}
body.color-black-green * {
  background-color: #000 !important;
  color: #c1e770 !important;
  fill: #c1e770 !important;
  border-color: #c1e770 !important;
}
body.color-black-green input {
  color: #c1e770 !important;
  font-size: 100% !important;
}
body.color-black-green input:active {
  outline: 2px solid #c1e770 !important;
}
body.color-black-green input:focus {
  outline: 2px solid #c1e770 !important;
}
body.color-black-green a:focus {
  outline: 2px dashed #c1e770 !important;
}
body.color-black-green textarea {
  color: #c1e770 !important;
  font-size: 100% !important;
}
body.color-black-green textarea:active {
  outline: 2px solid #c1e770 !important;
}
body.color-black-green textarea:focus {
  outline: 2px solid #c1e770 !important;
}
body.color-black-green button:focus {
  outline: 3px solid #c1e770 !important;
}
body.color-black-green ol li::before {
  color: #c1e770 !important;
}
body.color-black-green .active {
  border-color: #c1e770 !important;
  border-width: 1px;
  border-style: solid;
}
body.color-black-green img {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
}
body.color-black-green [data-value="black"] {
  background: #fff !important;
  color: #000 !important;
}
body.color-black-green [data-value="white"] {
  background: #000 !important;
  color: #fff !important;
}
body.color-black-green [data-value="blue"] {
  background: #abd4f6 !important;
  color: #002663 !important;
}
body.color-black-green [data-value="braun"] {
  background: #eee8c5 !important;
  color: #493934 !important;
}
body.color-black-green [data-value="black-green"] {
  background: #000 !important;
  color: #c1e770 !important;
}
body.color-black-green .header-top-toolbar-fontsize::before {
  border-top: 2px solid #c1e770 !important;
}
/*размер шрифта кнорок тулбара*/
[data-value="small"] {
  font-size: 16px !important;
}
[data-value="medium"] {
  font-size: 18px !important;
}
[data-value="large"] {
  font-size: 20px !important;
}
/*активные кнопки*/
[data-value="black"].active,
[data-value="white"].active,
[data-value="blue"].active,
[data-value="braun"].active,
[data-value="black-green"].active,
[data-value="small"].active,
[data-value="medium"].active,
[data-value="large"].active,
[data-value="0.5"].active,
[data-value="1"].active,
[data-value="1.5"].active {
  border-bottom: 10px solid #000 !important;
}
/*size-small*/
body.size-small {
  font-size: 16px !important;
}
body.size-small * {
  font-size: 100%;
}
body.size-small .h1,
body.size-small h1,
body.size-small .content h1,
body.size-small .content .h1 {
  font-size: 250% !important;
  /*40px*/
}
body.size-small .h1 small,
body.size-small h1 small,
body.size-small .content h1 small,
body.size-small .content .h1 small {
  font-size: 65% !important;
}
body.size-small .h2,
body.size-small h2,
body.size-small .content h2,
body.size-small .content .h2 {
  font-size: 225% !important;
  /*36px*/
}
body.size-small .h2 small,
body.size-small h2 small,
body.size-small .content h2 small,
body.size-small .content .h2 small {
  font-size: 65% !important;
}
body.size-small .h3,
body.size-small h3,
body.size-small .content h3,
body.size-small .content .h3 {
  font-size: 187% !important;
  /*30px*/
}
body.size-small .h3 small,
body.size-small h3 small,
body.size-small .content h3 small,
body.size-small .content .h3 small {
  font-size: 65% !important;
}
body.size-small .h4,
body.size-small h4,
body.size-small .content h4,
body.size-small .content .h4 {
  font-size: 125% !important;
  /*20px*/
}
body.size-small .h4 small,
body.size-small h4 small,
body.size-small .content h4 small,
body.size-small .content .h4 small {
  font-size: 65% !important;
}
body.size-small .h5,
body.size-small h5,
body.size-small .content h5,
body.size-small .content .h5 {
  font-size: 100%;
  /*16px*/
}
body.size-small .h5 small,
body.size-small h5 small,
body.size-small .content h5 small,
body.size-small .content .h5 small {
  font-size: 65% !important;
}
body.size-small .h6,
body.size-small h6,
body.size-small .content h6,
body.size-small .content .h6 {
  font-size: 88% !important;
  /*14px*/
}
body.size-small .h6 small,
body.size-small h6 small,
body.size-small .content h6 small,
body.size-small .content .h6 small {
  font-size: 65% !important;
}
body.size-small code,
body.size-small pre {
  font-size: 88% !important;
  /*14px*/
}
body.size-small .a,
body.size-small .content a {
  font-size: 100% !important;
  /*16px*/
}
body.size-small .content ol ol li::before {
  font-size: 113% !important;
  /*18px*/
}
body.size-small .content ol li::before {
  font-size: 113% !important;
  /*18px*/
}
body.size-small .content blockquote {
  font-size: 125% !important;
  /*20px*/
}
body.size-small .content blockquote footer,
body.size-small .content blockquote small {
  font-size: 100% !important;
  /*16px*/
}
body.size-small .breadcrumb a {
  font-size: 88% !important;
  /*14px*/
}
body.size-small .btn,
body.size-small .content .btn {
  font-size: 125% !important;
  /*20px*/
}
body.size-small .btn--sm,
body.size-small .content .btn--sm {
  font-size: 88% !important;
  /*14px*/
}
body.size-small .spoiler__title {
  font-size: 113% !important;
  /*18px*/
}
body.size-small .pagination a,
body.size-small .content .pagination a,
body.size-small .pagination span,
body.size-small .content .pagination span {
  font-size: 100% !important;
  /*16px*/
}
body.size-small .content .tab a,
body.size-small .tab a {
  font-size: 125% !important;
  /*20px*/
}
body.size-small .tooltip {
  font-size: 88% !important;
  /*14px*/
}
body.size-small .header * {
  font-size: 100%;
}
body.size-small .footer * {
  font-size: 100%;
}
/*//size-small*/
/*size-medium*/
body.size-medium {
  font-size: 24px !important;
}
body.size-medium * {
  font-size: 100%;
}
body.size-medium .h1,
body.size-medium h1,
body.size-medium .content h1,
body.size-medium .content .h1 {
  font-size: 250% !important;
  /*40px*/
}
body.size-medium .h1 small,
body.size-medium h1 small,
body.size-medium .content h1 small,
body.size-medium .content .h1 small {
  font-size: 65% !important;
}
body.size-medium .h2,
body.size-medium h2,
body.size-medium .content h2,
body.size-medium .content .h2 {
  font-size: 225% !important;
  /*36px*/
}
body.size-medium .h2 small,
body.size-medium h2 small,
body.size-medium .content h2 small,
body.size-medium .content .h2 small {
  font-size: 65% !important;
}
body.size-medium .h3,
body.size-medium h3,
body.size-medium .content h3,
body.size-medium .content .h3 {
  font-size: 187% !important;
  /*30px*/
}
body.size-medium .h3 small,
body.size-medium h3 small,
body.size-medium .content h3 small,
body.size-medium .content .h3 small {
  font-size: 65% !important;
}
body.size-medium .h4,
body.size-medium h4,
body.size-medium .content h4,
body.size-medium .content .h4 {
  font-size: 125% !important;
  /*20px*/
}
body.size-medium .h4 small,
body.size-medium h4 small,
body.size-medium .content h4 small,
body.size-medium .content .h4 small {
  font-size: 65% !important;
}
body.size-medium .h5,
body.size-medium h5,
body.size-medium .content h5,
body.size-medium .content .h5 {
  font-size: 100%;
  /*16px*/
}
body.size-medium .h5 small,
body.size-medium h5 small,
body.size-medium .content h5 small,
body.size-medium .content .h5 small {
  font-size: 65% !important;
}
body.size-medium .h6,
body.size-medium h6,
body.size-medium .content h6,
body.size-medium .content .h6 {
  font-size: 88% !important;
  /*14px*/
}
body.size-medium .h6 small,
body.size-medium h6 small,
body.size-medium .content h6 small,
body.size-medium .content .h6 small {
  font-size: 65% !important;
}
body.size-medium code,
body.size-medium pre {
  font-size: 88% !important;
  /*14px*/
}
body.size-medium .a,
body.size-medium .content a {
  font-size: 100% !important;
  /*16px*/
}
body.size-medium .content ol ol li::before {
  font-size: 113% !important;
  /*18px*/
}
body.size-medium .content ol li::before {
  font-size: 113% !important;
  /*18px*/
}
body.size-medium .content blockquote {
  font-size: 125% !important;
  /*20px*/
}
body.size-medium .content blockquote footer,
body.size-medium .content blockquote small {
  font-size: 100% !important;
  /*16px*/
}
body.size-medium .breadcrumb a {
  font-size: 88% !important;
  /*14px*/
}
body.size-medium .btn,
body.size-medium .content .btn {
  font-size: 125% !important;
  /*20px*/
}
body.size-medium .btn--sm,
body.size-medium .content .btn--sm {
  font-size: 88% !important;
  /*14px*/
}
body.size-medium .spoiler__title {
  font-size: 113% !important;
  /*18px*/
}
body.size-medium .pagination a,
body.size-medium .content .pagination a,
body.size-medium .pagination span,
body.size-medium .content .pagination span {
  font-size: 100% !important;
  /*16px*/
}
body.size-medium .content .tab a,
body.size-medium .tab a {
  font-size: 125% !important;
  /*20px*/
}
body.size-medium .tooltip {
  font-size: 88% !important;
  /*14px*/
}
body.size-medium .header * {
  font-size: 100%;
}
body.size-medium .footer * {
  font-size: 100%;
}
/*//size-medium*/
/*size-large*/
body.size-large {
  font-size: 32px !important;
}
body.size-large * {
  font-size: 100%;
}
body.size-large .h1,
body.size-large h1,
body.size-large .content h1,
body.size-large .content .h1 {
  font-size: 250% !important;
  /*40px*/
}
body.size-large .h1 small,
body.size-large h1 small,
body.size-large .content h1 small,
body.size-large .content .h1 small {
  font-size: 65% !important;
}
body.size-large .h2,
body.size-large h2,
body.size-large .content h2,
body.size-large .content .h2 {
  font-size: 225% !important;
  /*36px*/
}
body.size-large .h2 small,
body.size-large h2 small,
body.size-large .content h2 small,
body.size-large .content .h2 small {
  font-size: 65% !important;
}
body.size-large .h3,
body.size-large h3,
body.size-large .content h3,
body.size-large .content .h3 {
  font-size: 187% !important;
  /*30px*/
}
body.size-large .h3 small,
body.size-large h3 small,
body.size-large .content h3 small,
body.size-large .content .h3 small {
  font-size: 65% !important;
}
body.size-large .h4,
body.size-large h4,
body.size-large .content h4,
body.size-large .content .h4 {
  font-size: 125% !important;
  /*20px*/
}
body.size-large .h4 small,
body.size-large h4 small,
body.size-large .content h4 small,
body.size-large .content .h4 small {
  font-size: 65% !important;
}
body.size-large .h5,
body.size-large h5,
body.size-large .content h5,
body.size-large .content .h5 {
  font-size: 100%;
  /*16px*/
}
body.size-large .h5 small,
body.size-large h5 small,
body.size-large .content h5 small,
body.size-large .content .h5 small {
  font-size: 65% !important;
}
body.size-large .h6,
body.size-large h6,
body.size-large .content h6,
body.size-large .content .h6 {
  font-size: 88% !important;
  /*14px*/
}
body.size-large .h6 small,
body.size-large h6 small,
body.size-large .content h6 small,
body.size-large .content .h6 small {
  font-size: 65% !important;
}
body.size-large code,
body.size-large pre {
  font-size: 88% !important;
  /*14px*/
}
body.size-large .a,
body.size-large .content a {
  font-size: 100% !important;
  /*16px*/
}
body.size-large .content ol ol li::before {
  font-size: 113% !important;
  /*18px*/
}
body.size-large .content ol li::before {
  font-size: 113% !important;
  /*18px*/
}
body.size-large .content blockquote {
  font-size: 125% !important;
  /*20px*/
}
body.size-large .content blockquote footer,
body.size-large .content blockquote small {
  font-size: 100% !important;
  /*16px*/
}
body.size-large .breadcrumb a {
  font-size: 88% !important;
  /*14px*/
}
body.size-large .btn,
body.size-large .content .btn {
  font-size: 125% !important;
  /*20px*/
}
body.size-large .btn--sm,
body.size-large .content .btn--sm {
  font-size: 88% !important;
  /*14px*/
}
body.size-large .spoiler__title {
  font-size: 113% !important;
  /*18px*/
}
body.size-large .pagination a,
body.size-large .content .pagination a,
body.size-large .pagination span,
body.size-large .content .pagination span {
  font-size: 100% !important;
  /*16px*/
}
body.size-large .content .tab a,
body.size-large .tab a {
  font-size: 125% !important;
  /*20px*/
}
body.size-large .tooltip {
  font-size: 88% !important;
  /*14px*/
}
body.size-large .header * {
  font-size: 100%;
}
body.size-large .footer * {
  font-size: 100%;
}
/*//size-large*/
/*.visually-impaired__font-size*/
.visually-impaired__font-size * {
  font-size: 100%;
}
.visually-impaired__font-size .h1,
.visually-impaired__font-size h1,
.visually-impaired__font-size .content h1,
.visually-impaired__font-size .content .h1 {
  font-size: 250% !important;
  /*40px*/
}
.visually-impaired__font-size .h1 small,
.visually-impaired__font-size h1 small,
.visually-impaired__font-size .content h1 small,
.visually-impaired__font-size .content .h1 small {
  font-size: 65% !important;
}
.visually-impaired__font-size .h2,
.visually-impaired__font-size h2,
.visually-impaired__font-size .content h2,
.visually-impaired__font-size .content .h2 {
  font-size: 225% !important;
  /*36px*/
}
.visually-impaired__font-size .h2 small,
.visually-impaired__font-size h2 small,
.visually-impaired__font-size .content h2 small,
.visually-impaired__font-size .content .h2 small {
  font-size: 65% !important;
}
.visually-impaired__font-size .h3,
.visually-impaired__font-size h3,
.visually-impaired__font-size .content h3,
.visually-impaired__font-size .content .h3 {
  font-size: 187% !important;
  /*30px*/
}
.visually-impaired__font-size .h3 small,
.visually-impaired__font-size h3 small,
.visually-impaired__font-size .content h3 small,
.visually-impaired__font-size .content .h3 small {
  font-size: 65% !important;
}
.visually-impaired__font-size .h4,
.visually-impaired__font-size h4,
.visually-impaired__font-size .content h4,
.visually-impaired__font-size .content .h4 {
  font-size: 125% !important;
  /*20px*/
}
.visually-impaired__font-size .h4 small,
.visually-impaired__font-size h4 small,
.visually-impaired__font-size .content h4 small,
.visually-impaired__font-size .content .h4 small {
  font-size: 65% !important;
}
.visually-impaired__font-size .h5,
.visually-impaired__font-size h5,
.visually-impaired__font-size .content h5,
.visually-impaired__font-size .content .h5 {
  font-size: 100%;
  /*16px*/
}
.visually-impaired__font-size .h5 small,
.visually-impaired__font-size h5 small,
.visually-impaired__font-size .content h5 small,
.visually-impaired__font-size .content .h5 small {
  font-size: 65% !important;
}
.visually-impaired__font-size .h6,
.visually-impaired__font-size h6,
.visually-impaired__font-size .content h6,
.visually-impaired__font-size .content .h6 {
  font-size: 88% !important;
  /*14px*/
}
.visually-impaired__font-size .h6 small,
.visually-impaired__font-size h6 small,
.visually-impaired__font-size .content h6 small,
.visually-impaired__font-size .content .h6 small {
  font-size: 65% !important;
}
.visually-impaired__font-size code,
.visually-impaired__font-size pre {
  font-size: 88% !important;
  /*14px*/
}
.visually-impaired__font-size .a,
.visually-impaired__font-size .content a {
  font-size: 100% !important;
  /*16px*/
}
.visually-impaired__font-size .content ol ol li::before {
  font-size: 113% !important;
  /*18px*/
}
.visually-impaired__font-size .content ol li::before {
  font-size: 113% !important;
  /*18px*/
}
.visually-impaired__font-size .content blockquote {
  font-size: 125% !important;
  /*20px*/
}
.visually-impaired__font-size .content blockquote footer,
.visually-impaired__font-size .content blockquote small {
  font-size: 100% !important;
  /*16px*/
}
.visually-impaired__font-size .breadcrumb a {
  font-size: 88% !important;
  /*14px*/
}
.visually-impaired__font-size .btn,
.visually-impaired__font-size .content .btn {
  font-size: 125% !important;
  /*20px*/
}
.visually-impaired__font-size .btn--sm,
.visually-impaired__font-size .content .btn--sm {
  font-size: 88% !important;
  /*14px*/
}
.visually-impaired__font-size .spoiler__title {
  font-size: 113% !important;
  /*18px*/
}
.visually-impaired__font-size .pagination a,
.visually-impaired__font-size .content .pagination a,
.visually-impaired__font-size .pagination span,
.visually-impaired__font-size .content .pagination span {
  font-size: 100% !important;
  /*16px*/
}
.visually-impaired__font-size .content .tab a,
.visually-impaired__font-size .tab a {
  font-size: 125% !important;
  /*20px*/
}
.visually-impaired__font-size .tooltip {
  font-size: 88% !important;
  /*14px*/
}
.visually-impaired__font-size .header * {
  font-size: 100%;
}
.visually-impaired__font-size .footer * {
  font-size: 100%;
}
/*//visually-impaired__font-size*/
/*блок с цветом*/
.visually-impaired__colors * {
  background-color: #fff !important;
  color: #000 !important;
  fill: #000 !important;
  border-color: #000 !important;
}
.visually-impaired__colors input {
  color: #000 !important;
  font-size: 100% !important;
}
.visually-impaired__colors input:active {
  outline: 2px solid #000 !important;
}
.visually-impaired__colors input:focus {
  outline: 2px solid #000 !important;
}
.visually-impaired__colors a:focus {
  outline: 2px dashed #000 !important;
}
.visually-impaired__colors textarea {
  color: #000 !important;
  font-size: 100% !important;
}
.visually-impaired__colors textarea:active {
  outline: 2px solid #000 !important;
}
.visually-impaired__colors textarea:focus {
  outline: 2px solid #000 !important;
}
.visually-impaired__colors button:focus {
  outline: 3px solid #000 !important;
}
.visually-impaired__colors ol li::before {
  color: #000 !important;
}
.visually-impaired__colors .active {
  border-color: #000;
  border-width: 1px;
  border-style: solid;
}
.visually-impaired__colors img {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
}
body.interval-05 {
  letter-spacing: 0px !important;
}
body.interval-05 * {
  letter-spacing: 0px !important;
}
body.interval-1 {
  letter-spacing: 3px !important;
}
body.interval-1 * {
  letter-spacing: 3px !important;
}
body.interval-15 {
  letter-spacing: 6px !important;
}
body.interval-15 * {
  letter-spacing: 6px !important;
}
/*размер шрифта тулбара*/
.toolbar-visually-impaired {
  font-size: 18px !important;
}
body.interval-05 .toolbar-visually-impaired,
body.interval-1 .toolbar-visually-impaired,
body.interval-15 .toolbar-visually-impaired {
  font-size: 18px !important;
  letter-spacing: 0 !important;
}
body.interval-05 .toolbar-visually-impaired *,
body.interval-1 .toolbar-visually-impaired *,
body.interval-15 .toolbar-visually-impaired * {
  letter-spacing: 0 !important;
}
/*зачеркивание глаза*/
.visuallyimpaired .header-top-toolbar-fontsize {
  position: relative !important;
  font-size: 14px !important;
  height: 20px !important;
  line-height: 20px !important;
}
.visuallyimpaired .header-top-toolbar-fontsize:before {
  border-top: 2px solid #000;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: rotate(-30deg);
  width: 100%;
}
@media (max-width: 768px) {
  .toolbar-visually-impaired {
    height: 236px;
    flex-direction: column;
  }
  .toolbar-visually-impaired [data-action="color"] {
    display: flex;
    flex-wrap: wrap;
  }
  .toolbar-visually-impaired span {
    margin-bottom: 5px !important;
  }
  .visuallyimpaired .header__mobile-menu-button div {
    background: red !important;
  }
  .visuallyimpaired .header__mobile-menu-button.active div {
    background: red !important;
  }
}
@media (max-width: 414px) {
  .visuallyimpaired .mobile-menu {
    border: 3px solid #000;
  }
  .visuallyimpaired .header-mobile-menu {
    height: calc(100vh - 301px);
    border: 3px solid #000;
    overflow-y: scroll;
    position: absolute;
    z-index: 2;
    background: rgba(49, 48, 48, 0.99);
    width: 100%;
    left: 0;
  }
}
.toolbar-visually-impaired {
  position: relative;
  z-index: 10;
}
