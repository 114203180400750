/*visually_impaired*/

/*кнопки панели toolbar-visually-impaired*/
.toolbar-visually-impaired__buttons {
	[data-value="black"] {
		background: #fff !important;
		color: #000 !important;
	}

	[data-value="white"] {
		background: #000 !important;
		color: #fff !important;
	}

	[data-value="blue"] {
		background: #abd4f6 !important;
		color: #002663 !important;
	}

	[data-value="braun"] {
		background: #eee8c5 !important;
		color: #493934 !important;
	}

	[data-value="black-green"] {
		background: #000 !important;
		color: #c1e770 !important;
	}
}


/*страница для слабовидящих по умолчанию*/
.visuallyimpaired {
	background-image: none !important;
	background-color: transparent !important;

	* {
		background-image: none !important;
		background-color: transparent !important;
	}

	body, div, h1, h2, h3, h4, h5, h6, ul, li, table, tr, td, form, input, textarea, p, a, select, * {
	    color: #000 !important;
	    font-family: Verdana !important;
	    font-weight: normal !important;
	    letter-spacing: 0.01em !important;
	    line-height: 1.5 !important;
	}

	@color: #000 !important;
	@background-color: #fff;
	.visually-impaired__colors;
	.toolbar-visually-impaired__buttons;
	.visuallyimpaired .header-top-toolbar-fontsize {	
		&:before {
			border-top: 2px solid @color;	    
		}
	}
}
/*visuallyimpaired*/





/*цвета*/
body.color-black {
	@color: #000 !important;
	@background-color: #fff;
	.visually-impaired__colors;
	.toolbar-visually-impaired__buttons;
	.header-top-toolbar-fontsize::before {		
		border-top: 2px solid @color !important;
	}
}


body.color-white {
	@color: #fff !important;
	@background-color: #000;
	.visually-impaired__colors;
	.toolbar-visually-impaired__buttons;
	.header-top-toolbar-fontsize::before {		
		border-top: 2px solid @color !important;
	}
}


body.color-blue {
	@color: #002663 !important;
	@background-color: #abd4f6;
	.visually-impaired__colors;
	.toolbar-visually-impaired__buttons;
	.header-top-toolbar-fontsize::before {		
			border-top: 2px solid @color !important;
	}
}


body.color-braun {
	@color: #493934 !important;
	@background-color: #eee8c5;
	.visually-impaired__colors;
	.toolbar-visually-impaired__buttons;
	.header-top-toolbar-fontsize::before {		
		border-top: 2px solid @color !important;
	}
}


body.color-black-green {
	@color: #c1e770 !important;
	@background-color: #000;
	.visually-impaired__colors;
	.toolbar-visually-impaired__buttons;
	.header-top-toolbar-fontsize::before {		
		border-top: 2px solid @color !important;
	}
}



/*размер шрифта кнорок тулбара*/
[data-value="small"] {
	font-size: 16px !important;
}

[data-value="medium"] {
	font-size: 18px !important;
}

[data-value="large"] {
	font-size: 20px !important;
}

/*активные кнопки*/
[data-value="black"].active,
[data-value="white"].active,
[data-value="blue"].active,
[data-value="braun"].active,
[data-value="black-green"].active,
[data-value="small"].active,
[data-value="medium"].active,
[data-value="large"].active,
[data-value="0.5"].active,
[data-value="1"].active,
[data-value="1.5"].active {
	border-bottom: 10px solid #000 !important;
}

/*size-small*/
body.size-small {
    font-size: 16px !important; 
    .visually-impaired__font-size;   
}
/*//size-small*/

/*size-medium*/
body.size-medium {
    font-size: 24px !important;   
    .visually-impaired__font-size; 
}
/*//size-medium*/

/*size-large*/
body.size-large {
    font-size: 32px !important;
    .visually-impaired__font-size;   
}
/*//size-large*/



/*.visually-impaired__font-size*/
.visually-impaired__font-size {
	* {
		font-size: 100%;
	}
	.h1,
	h1,
	.content h1,
	.content .h1 {  
	  font-size: 250% !important; /*40px*/
	}
	.h1 small,
	h1 small,
	.content h1 small,
	.content .h1 small {
	  font-size: 65% !important;
	}
	.h2,
	h2,
	.content h2,
	.content .h2 {
	  font-size: 225% !important; /*36px*/
	}
	.h2 small,
	h2 small,
	.content h2 small,
	.content .h2 small {
	  font-size: 65% !important;
	}
	.h3,
	h3,
	.content h3,
	.content .h3 {
	  font-size: 187% !important; /*30px*/
	}
	.h3 small,
	h3 small,
	.content h3 small,
	.content .h3 small {
	  font-size: 65% !important;
	}
	.h4,
	h4,
	.content h4,
	.content .h4 {
	  font-size: 125% !important; /*20px*/
	}
	.h4 small,
	h4 small,
	.content h4 small,
	.content .h4 small {
	  font-size: 65% !important;
	}
	.h5,
	h5,
	.content h5,
	.content .h5 {
	  font-size: 100%; /*16px*/
	}
	.h5 small,
	h5 small,
	.content h5 small,
	.content .h5 small {
	  font-size: 65% !important;
	}
	.h6,
	h6,
	.content h6,
	.content .h6 {
	  font-size: 88% !important; /*14px*/
	}
	.h6 small,
	h6 small,
	.content h6 small,
	.content .h6 small {
	  font-size: 65% !important;
	}
	code,
	pre {
	  font-size: 88% !important; /*14px*/
	}
	.a,
	.content a {
	  font-size: 100% !important; /*16px*/
	}
	.content ol ol li::before {  
	  font-size: 113% !important; /*18px*/
	}
	.content ol li::before {  
	  font-size: 113% !important; /*18px*/
	}
	.content blockquote {
	  font-size: 125% !important; /*20px*/
	}
	.content blockquote footer,
	.content blockquote small {
	  font-size: 100% !important; /*16px*/
	}
	.breadcrumb a {
	  font-size: 88% !important; /*14px*/
	}
	.btn,
	.content .btn {
	  font-size: 125% !important; /*20px*/
	}
	.btn--sm,
	.content .btn--sm {
	  font-size: 88% !important; /*14px*/
	}
	.spoiler__title {
	  font-size: 113% !important; /*18px*/
	}
	.pagination a,
	.content .pagination a,
	.pagination span,
	.content .pagination span {
	  font-size: 100% !important; /*16px*/
	}
	.content .tab a,
	.tab a {
	  font-size: 125% !important; /*20px*/
	}
	.tooltip {
	  font-size: 88% !important; /*14px*/
	}
	.header {
	* {
		font-size: 100%;
	}
	}
	.footer {
		* {
			font-size: 100%;
		}
	}
}
/*//visually-impaired__font-size*/


/*блок с цветом*/
@color: #000;
@background-color: #fff;
.visually-impaired__colors {
	* {
		background-color: @background-color !important;
	    color: @color !important;
	    fill: @color !important;
	    border-color: @color !important;
	}
	input {
	  color: @color !important;
	  font-size: 100% !important;
	  &:active {
	  	outline: 2px solid @color !important;
	  }
	  &:focus {
	  	outline: 2px solid @color !important;
	  }
	}
	a:focus {
		outline: 2px dashed @color !important;
	}
	textarea {
		color: @color !important;
		  font-size: 100% !important;
		  &:active {
		  	outline: 2px solid @color !important;
		  }
		  &:focus {
		  	outline: 2px solid @color !important;
		  }
	}
	button:focus {
	  	outline: 3px solid @color !important;
	}
	ol li::before {
		color: @color !important;
	}
	.active {
		border-color: @color;
		border-width: 1px;
		border-style: solid;
	}
	img {
	   filter: grayscale(100%);
	   -webkit-filter: grayscale(100%);
	   -moz-filter: grayscale(100%);
	   -ms-filter: grayscale(100%);
	   -o-filter: grayscale(100%);
	   filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
	}
	
}

body.interval-05 {
	letter-spacing: 0px !important;
	* {
		letter-spacing: 0px !important;
	}
}

body.interval-1 {
	letter-spacing: 3px !important;
	* {
		letter-spacing: 3px !important;
	}
}

body.interval-15 {
	letter-spacing: 6px !important;
	* {
		letter-spacing: 6px !important;
	}
}


/*размер шрифта тулбара*/
.toolbar-visually-impaired {
	font-size: 18px !important;
}
body.interval-05 .toolbar-visually-impaired,
body.interval-1 .toolbar-visually-impaired,
body.interval-15 .toolbar-visually-impaired {
	font-size: 18px !important;
	letter-spacing: 0 !important;
	* {
		letter-spacing: 0 !important;
	}
}

/*зачеркивание глаза*/
.visuallyimpaired .header-top-toolbar-fontsize {
	position: relative !important;
	font-size: 14px !important;
	height: 20px !important;
	line-height: 20px !important;
	&:before {
		border-top: 2px solid #000;
	    content: "";
	    display: block;
	    height: 1px;
	    left: 0;
	    position: absolute;
	    top: 50%;
	    transform: rotate(-30deg);
	    width: 100%;
	}
}
@media (max-width: 768px) {
	.toolbar-visually-impaired {
		height: 236px;
		flex-direction: column;
		[data-action="color"] {
			display: flex;
			flex-wrap: wrap;
		}
		span {
			margin-bottom: 5px !important;
		}
	}
	.visuallyimpaired .header__mobile-menu-button div {
		background: red !important;
	}
	.visuallyimpaired .header__mobile-menu-button.active div {
		background: red !important;
	}
}
@media (max-width: 414px) {
	.visuallyimpaired .mobile-menu {
		border: 3px solid #000;
	}
	.visuallyimpaired .header-mobile-menu {		
	    height: ~'calc(100vh - 301px)';
	    border: 3px solid #000;
	    overflow-y: scroll;
	    position: absolute;
	    z-index: 2;
	    background: rgba(49, 48, 48, 0.99);
	    width: 100%;
	    left: 0;
	}

}
.toolbar-visually-impaired {
	position: relative;
	z-index: 10;
}